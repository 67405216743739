<template>
    <input v-model="state" class="text-end" min="1" step="1" type="number" @input="update"/>
</template>

<script lang="ts">
import axios from "axios";
import NatchGtm from "natch-gtm";
import {defineComponent} from "vue";

import "../script/http/set-axios-defaults";
import debounce from "lodash-es/debounce";
import eventBus from "../script/vuetils/event-bus";

const natchGtm = new NatchGtm();

function changeLineQuantity(self: any) {
  const newQuantity = parseInt(self.state);
  axios
    .post("/basket/update", {lineGroupGuid: self.lineGroupGuid, quantity: newQuantity})
    .then((_) => self.$emit("change"))
    .then((_) => {
      let diff = newQuantity - self.quantity;
      if (diff < 0) {
        natchGtm.trackRemoveFromCart(self.dataLayerProduct, -diff);
      } else {
        natchGtm.trackAddToCart(self.dataLayerProduct, diff);
      }
      self.previousState = newQuantity;
    });
}

let debounced = debounce(changeLineQuantity, 1000);

export default defineComponent({
  props: {
    lineGroupGuid: {
      type: String,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
    dataLayerProduct: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      previousState: this.quantity,
      state: this.quantity,
    };
  },
  methods: {
    update: function () {
      debounced(this);
    },
  },
  name: "BasketLineQuantityUpdater",
  mounted() {
    eventBus.on("basket-line-updated", (eventData) => {
      console.log("BasketLineQuantityUpdater received basket-updated event");
      console.log(eventData);
      if (eventData.lineGroupGuid === this.lineGroupGuid) {
        this.previousState = eventData.quantity;
        this.state = eventData.quantity;
      }
    });
  },
});
</script>

<style scoped>
input {
    max-width: 6rem;
}
</style>
