<template>
    <basket-view id="basket-view-table" footer-class="nt-text-l" header-class="nt-text-l" price-class="nt-text-l">
    </basket-view>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BasketView from "./BasketView.vue";

export default defineComponent({
  name: "ContentsBasketView",
  components: {
    BasketView,
  },
});
</script>

<style>
</style>
