import {createApp} from "vue";
import emitter from "./script/vuetils/event-bus";

// import mitt from "mitt";                  // Import mitt
// const emitter = mitt();                   // Initialize mitt
import ContentsBasketView from "./vue-components/ContentsBasketView.vue";
import DetailsBasketView from "./vue-components/DetailsBasketView.vue";


createApp(ContentsBasketView)
  .provide('emitter', emitter)
  .mount(".js-vue--contents-basket-view");

createApp(DetailsBasketView)
  .mount(".js-vue--details-basket-view");




