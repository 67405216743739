<template>
    <table :class="readOnly ? 'nt-table-readonly sticky-top' : ''" class="table table-sm nt-basket-view"
           v-bind="$attrs">
        <thead :class="headerClass">
        <tr>
            <th scope="col">
                {{ !readOnly ? texts["checkout.content.product"] : texts["checkout.content.title_readonly"] }}
            </th>
            <th scope="col"></th>
            <th class="text-center d-none d-lg-table-cell" scope="col">{{ texts["checkout.content.price"] }}</th>
            <th :class="!readOnly ? 'd-none' : ''" class="text-center d-lg-table-cell" scope="col">
                {{ texts["checkout.content.quantity"] }}
            </th>
            <th :class="!readOnly ? 'nt-price-fixed-width' : ''" class="text-center" scope="col">
                {{ texts["checkout.content.total_product_price"] }}
            </th>
            <th v-if="!readOnly" :class="!readOnly ? 'nt-delete-fixed-width' : ''" scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr
                v-for="basketline in content.lines"
                :key="basketline.eid"
                :data-datalayer-cartitem="json(basketline.dataLayerProduct)"
        >
            <td class="product-image-link">
                <a :href="basketline.productPageUrl">
                    <img
                            v-if="!!basketline.imageUrl"
                            :alt="basketline.description"
                            :src="basketline.imageUrl"
                            class="img-fluid"
                            loading="lazy"
                    />
                </a>
            </td>
            <td>
                <div class="nt-text-s">
                    <a :href="basketline.productPageUrl">
                        <span class="text-uppercase me-2">{{ basketline.description }}</span>
                        <span class="text-nowrap">{{ basketline.productDiameter }}</span>
                    </a>
                </div>
                <div class="nt-text-s">
                    {{ texts["checkout.content.reference_abbreviated"] }} {{ basketline.productNumber }}
                </div>
                <div
                        v-if="!readOnly && basketline.eid && basketline.quantity"
                        :class="priceClass"
                        class="d-lg-none text-center text-nowrap"
                >
                    <basket-line-quantity-updater
                            :dataLayerProduct="basketline.dataLayerProduct"
                            :lineGroupGuid="basketline.lineGroupGuid"
                            :quantity="basketline.quantity"
                            @change="sync"
                    ></basket-line-quantity-updater>
                </div>
            </td>
            <td :class="priceClass" class="d-none d-lg-table-cell text-end"
                v-html="basketline.salesPriceExclVatHtml"></td>
            <td :class="!readOnly ? 'd-none' : ''" class="d-lg-table-cell text-center">
                <b v-if="!!readOnly" :class="priceClass">{{ basketline.quantity }}</b>
                <div v-if="!readOnly && basketline.eid && basketline.quantity" :class="priceClass">
                    <basket-line-quantity-updater
                            :dataLayerProduct="basketline.dataLayerProduct"
                            :lineGroupGuid="basketline.lineGroupGuid"
                            :quantity="basketline.quantity"
                            @change="sync"
                    ></basket-line-quantity-updater>
                </div>
            </td>
            <td :class="priceClass" class="text-end" v-html="basketline.totalLineAmountExclVatHtml"></td>
            <td v-if="!readOnly" class="text-end">
                <i
                        class="far fa-trash-alt nt-remove-basket-line"
                        @click="removeLine(basketline.lineGroupGuid, basketline.quantity, basketline.dataLayerProduct)"
                >
                </i>
            </td>
        </tr>
        </tbody>
        <tfoot :class="footerClass">
        <tr>
            <td class="d-none d-lg-table-cell"></td>
            <td :class="!readOnly ? 'd-lg-table-cell' : ''" class="d-none"></td>
            <td :colspan="readOnly ? 3 : 2">{{ texts["checkout.content.totalpriceproducts"] }}</td>
            <td class="font-weight-normal d-flex justify-content-end" v-html="content.totalExclShippingHtml"></td>
            <td v-if="!readOnly"></td>
        </tr>
        <tr>
            <td class="d-none d-lg-table-cell"></td>
            <td :class="!readOnly ? 'd-lg-table-cell' : ''" class="d-none"></td>
            <td v-if="content.onlineDiscountPercentage !== '0'" :colspan="readOnly ? 3 : 2">
                {{ getFilledInOnlineDiscountText() }}
            </td>
            <td v-if="content.onlineDiscountPercentage !== '0'" class="font-weight-normal d-flex justify-content-end"
                v-html="content.onlineDiscountHtml"></td>
            <td v-if="!readOnly"></td>
        </tr>
        <tr></tr>
        <tr>
            <td class="d-none d-lg-table-cell"></td>
            <td :class="!readOnly ? 'd-lg-table-cell' : ''" class="d-none"></td>
            <td :colspan="readOnly ? 3 : 2">{{ texts["checkout.content.shippingcosts"] }}</td>
            <td class="font-weight-normal d-flex justify-content-end" v-html="content.shippingCostsHtml"></td>
            <td v-if="!readOnly"></td>
        </tr>
        <tr></tr>
        <tr>
            <td class="d-none d-lg-table-cell"></td>
            <td :class="!readOnly ? 'd-lg-table-cell' : ''" class="d-none"></td>
            <td :colspan="readOnly ? 3 : 2">
                <b>{{ texts["checkout.content.total_price"] }}</b>
                <br/>
                <small class="nt-text-de-emphasized">{{ texts["checkout.content.excl_vat"] }}</small>
            </td>
            <td class="d-flex justify-content-end" v-html="content.totalHtml"></td>
            <td v-if="!readOnly"></td>
        </tr>
        </tfoot>
    </table>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import axios from "axios";
import BasketLineQuantityUpdater from "./BasketLineQuantityUpdater.vue";
import NatchGtm from "natch-gtm";

import "../script/http/set-axios-defaults";
import {Guid} from "../script/baskets/types";
import eventBus from "../script/vuetils/event-bus";
import type {BasketViewData} from "./basket-view-types";

const natchGtm = new NatchGtm();

let log = console.log;

export default defineComponent({
  inheritAttrs: false,
  props: {
    readOnly: {
      type: Boolean,
      required: false,
    },
    headerClass: {
      type: String,
      required: false,
    },
    footerClass: {
      type: String,
      required: false,
    },
    priceClass: {
      type: String,
      required: false,
    },
  },
  data(): BasketViewData {
    return {
      texts: {},
      content: {
        lines: [],
        totalHtml: "",
        totalExclShippingHtml: "",
        shippingCostsHtml: "",
        onlineDiscountPercentage: "",
        onlineDiscountHtml: "",
      },
      validationResult: {
        basketIsEmpty: true,
        isValid: false,
        unavailableProducts: []
      }
    };
  },
  methods: {
    sync: function () {
      console.log("BasketView sync() called");
      axios.get("/basket/content").then((response) => {
        const data = response.data as BasketViewData;
        Object.assign(this, data);
        eventBus.emit("basket-validated", data.validationResult)
      });
    },
    removeLine: function (lineGroupGuid: Guid, quantity: number, dataLayerProduct: any) {
      const self = this;
      axios
        .post("/basket/remove-line-group", {basketLineGroupGuid: lineGroupGuid})
        .then((_) => natchGtm.trackRemoveFromCart(dataLayerProduct, quantity))
        .then((_) => self.sync());
    },
    json(thing: any) {
      return JSON.stringify(thing);
    },
    getFilledInOnlineDiscountText() {
      return this.texts["checkout.content.onlinediscount"]?.replace(
        "{percentage}",
        this.content.onlineDiscountPercentage
      );
    },
  },
  mounted() {
    console.log("BasketView mounted() called");
    this.sync();

    eventBus.on("basket-updated", () => {
      console.log("handling basket-updated")
      this.sync();
    });
  },
  components: {
    BasketLineQuantityUpdater,
  },
});
</script>

<style scoped>
</style>
