<template>
    <basket-view
            id="basket-view-table"
            :read-only="true"
            footer-class="nt-text-m"
            header-class="nt-text-s"
            price-class="nt-text-s"
    >
    </basket-view>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BasketView from "./BasketView.vue";

export default defineComponent({
  name: "DetailsBasketView",
  components: {
    BasketView,
  },
});
</script>

<style>
</style>
